<!--
 * @Author: Jin Biao
 * @Date: 2022-02-15 15:12:18
 * @LastEditTime: 2023-01-15 21:36:35
 * @Description:  
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '版权',
          list: [
            {
              name: '网站信息',
              path: '/mall/configure/contact'
            },
            {
              name: '版权分类',
              path: '/mall/configure/category'
            },
            {
              name: '版权列表',
              path: '/mall/configure/copyright'
            }
          ]
        }
      ]
    };
  }
};
</script>
